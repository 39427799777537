import React from 'react';
// Pieces imports
import { SEO, Heading, Box } from 'pieces';
// sections

const LadenbauPage = () => (
  <>
    <SEO title='Messebau' />
    <Box sx={{ minHeight: '100vh' }}>
      <Heading>Ladenbau</Heading>
    </Box>
  </>
);

export default LadenbauPage;
